import React from 'react'
import "../css/Grendma.css";
import grandma2 from "../img/grandma2.jpg";

function Grendma() {
  return (
    <div className="grendma">
      <div className="grendma_img">
        <img
          className="grendma_img_img"
          src={grandma2}
          alt="Доступная стоимость парника Хлебница"
        />
      </div>
      <div className="grendma_block">
        <h3 className="grendma_h3">
          Доступная стоимость парника “Хлебница” позволяет оптимально обустроить
          дачный участок и с комфортом ухаживать за растениями. Работа на
          участке будет вам в радость, а богатый урожай приятно обрадует Вас и
          всю семью. Вложенные средства окупятся сполна!
        </h3>
      </div>
    </div>
  );
}

export {Grendma}