import React from "react";
import "../css/Modalla.css";

const Modalla = ({ active, setActive, children }) => {
  const addClasstoBody = () => {
    document.body.classList.add("modalactivebody");
  };
  const removeClasstoBody = () => {
    document.body.classList.remove("modalactivebody");
  };
  return (
    <>
      <div
        className={active ? "modal active" : "modal"}
        onClick={(
          active ? addClasstoBody() : removeClasstoBody(),
          () => setActive(false)
        )}
      >
        <div
          className={active ? "modal_content active" : "modal_content"}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </>
  );
};
export { Modalla };
