import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "../css/Ordercall.css";
import { NavLink } from "react-router-dom";
import telimg from "../img/telimg.png";
import { BsFillCheckSquareFill } from "react-icons/bs";

const useValidation = (valuesCall, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [inputvalid, setInputValid] = useState(false);
  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "isEmpty":
          valuesCall ? setEmpty(false) : setEmpty(true);
          break;
        default:
      }
    }
  }, [valuesCall, validations]);

  useEffect(() => {
    if (isEmpty) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [isEmpty]);

  return {
    isEmpty,
    inputvalid,
  };
};

const useInput = (validations) => {
  const [valuesCall, setValuesCall] = useState("");
  const valid = useValidation(valuesCall, validations);
  const handleChangeCall = (e) => {
    setValuesCall(e.target.value);
    // console.log(valuesReview);
  };
  return {
    valuesCall,
    handleChangeCall,
    ...valid,
  };
};

function Ordercall({ closeModal }) {

  const form3 = useRef();
  const handleSubmitss = (e) => {
    e.preventDefault();
    console.log(form3);
    emailjs
      .sendForm(
        "service_0e6mvwt",
        "template_74ctx0j",
        form3.current,
        "gq2_8BR8Z8QGkUWBx"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const syleError = {
    color: "red",
    fontSize: "0.9rem",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "500",
  };

  const namecall = useInput({ isEmpty: true });
  const telcall = useInput({ isEmpty: true });
  const citycall = useInput({ isEmpty: true });

  return (
    <div className="ordercall">
      <form ref={form3} onSubmit={handleSubmitss} className="ordercall_section">
        <div className="ordercall_head">
          <div className="ordercall_img">
            <img
              className="ordercall_img_img"
              src={telimg}
              alt="Заказать звонок"
            />
          </div>
          <h4 className="ordercall_h4">Заказать звонок</h4>
        </div>
        <div className="ordercall_block">
          <div className="ordercall_label_block">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Имя</span>
              <span className="ordercall_star">*</span>
            </div>
            {namecall.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="namecall"
            value={namecall.valuesCall}
            onChange={(e) => namecall.handleChangeCall(e)}
            type="text"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block">
          <div className="ordercall_label_block">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Телефон</span>
              <span className="ordercall_star">*</span>
            </div>
            {telcall.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="telcall"
            value={telcall.valuesCall}
            onChange={(e) => telcall.handleChangeCall(e)}
            type="tel"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block">
          <div className="ordercall_label_block">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Город</span>
              <span className="ordercall_star">*</span>
            </div>
            {citycall.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="citycall"
            value={citycall.valuesCall}
            onChange={(e) => citycall.handleChangeCall(e)}
            type="text"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block_polisy">
          <span className="ordercall_chek">
            <BsFillCheckSquareFill color="#b8d4a7" size="2rem" />
          </span>
          <span className="ordercall_polisy_text">
            Согласие на обработку персональных данных в соответствии с
            <NavLink
              to="polisy"
              className="ordercall_polisy_text ordercall_polisy_text2"
            >
              политикой конфиденциальности
            </NavLink>
          </span>
        </div>
        <div className="ordercall_block_button">
          <button
            disabled={
              namecall.isEmpty || telcall.isEmpty || citycall.isEmpty
            }
            onClick={() => closeModal()}
            className="ordercall_button"
          >
            Отправить
          </button>
        </div>
      </form>
    </div>
  );
}

export {Ordercall}