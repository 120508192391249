const gallery2 = [
  {
    name: "Фото16",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg17.jpg",
  },
  {
    name: "Фото16",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg18.jpg",
  },
  {
    name: "Фото9",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg9.jpg",
  },
  {
    name: "Фото10",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg10.jpg",
  },
  {
    name: "Фото11",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg11.jpg",
  },
  {
    name: "Фото12",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg12.jpg",
  },
  {
    name: "Фото13",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg13.jpg",
  },
  {
    name: "Фото14",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg14.jpg",
  },
  {
    name: "Фото15",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg15.jpg",
  },
  {
    name: "Фото16",
    key: Math.round(10000 + Math.random() * 20000),
    url: "gfg16.jpg",
  }

];
export { gallery2 };