import React from "react";
import { NavLink } from "react-router-dom";

function Menuheader1300() {
  return (
    <div className="menuheader1300">
      <div className="header_section1300">
        <div className="menu">
          <div className="menu-block">
            <nav className="menu-block1">
              <NavLink to="promotions" className="menu-item">
                Акции
              </NavLink>
              <NavLink to="about" className="menu-item">
                О компании
              </NavLink>
            </nav>
            <nav className="menu-block1">
              <NavLink to="gallery" className="menu-item">
                Фотогалерея
              </NavLink>
              <NavLink to="reviews" className="menu-item">
                Отзывы
              </NavLink>
            </nav>
            <nav className="menu-block1">
              <NavLink to="contacts" className="menu-item">
                Контакты
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Menuheader1300 };
