import React, {useState} from "react";
import { Modalla } from "../components/Modalla";
import { Formreview } from "../components/Formreview";
import { Header } from "../components/Header.jsx";
import { Screen1 } from "../components/Screen1.jsx";
import { Screen1mobile } from "../components/Screen1mobile.jsx";
import { Benefits } from "../components/Benefits.jsx";
import { Completeset } from "../components/Completeset.jsx";
import { Makeorder } from "../components/Makeorder.jsx";
import { Wildberries } from "../components/Wildberries.jsx";
import { Questions } from "../components/Questions.jsx";
import { Upac } from "../components/Upac.jsx";
import { Grendma } from "../components/Grendma.jsx";
import { Photogallery } from "../components/Photogallery.jsx";
import { Reviewscomponent } from "../components/Reviewscomponent.jsx";
import { Offer2 } from "../components/Offer2.jsx";
import { Aboutcomponent } from "../components/Aboutcomponent.jsx";
import { Footer } from "../components/Footer.jsx";
import { Footermobile } from "../components/Footermobile.jsx";
import { Buttonup } from "../components/Buttonup.jsx";
import arrow from "../icons/arrow.svg";
import { NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

function Mainpage() {
  const [modalActiveReview, setModalActiveReview] = useState(false);
  const [infoSendedReview, setInfoSendedReview] = useState("");
  const closeModalr = () => {
    setModalActiveReview(false);
    setInfoSendedReview(!infoSendedReview);
  };
  return (
    <>
      <div className="mainpage">
        <Header />
        <Screen1 />
        <Screen1mobile />
        <Benefits />
        <Completeset />
        <Makeorder />
        <Wildberries />
        <Questions />
        <Upac />
        <Grendma />
        <Photogallery />
        <div className="photogallery_button_section">
          <div className="photogallery_button_block">
            <div className="photogallery_button">
              <NavLink to="gallery" target="_blank">
                <button className="photogallery_button_b">
                  <span className="photogallery_button_text">Смотреть все</span>
                  <img
                    className="photogallery_button_arrow"
                    src={arrow}
                    alt="смотреть все"
                  />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <Reviewscomponent />
        <div className="reviewscomponent_button_section">
          <div className="reviewscomponent_button_block">
            <div className="reviewscomponent_button1">
              <NavLink to="reviews" target="_blank">
                <button className="reviewscomponent_button_b">
                  <span className="reviewscomponent_button_text">
                    Смотреть все
                  </span>
                  <img
                    className="reviewscomponent_button_arrow"
                    src={arrow}
                    alt="смотреть все"
                  />
                </button>
              </NavLink>
            </div>
            <div className="reviewscomponent_button2">
              <button
                className="reviewscomponent_button_b_r"
                onClick={() => setModalActiveReview(true)}
              >
                <img
                  className="reviewscomponent_button_arrow reviewscomponent_button_arrow_r"
                  src={arrow}
                  alt="оставить отзыв"
                />
                <span className="reviewscomponent_button_text_r">
                  Оставить отзыв
                </span>
              </button>
            </div>
          </div>
        </div>
        <Offer2 />
        <Wildberries />
        <Aboutcomponent />
        <Footer />
        <Footermobile />
        <Buttonup />
      </div>
      <Modalla active={modalActiveReview} setActive={setModalActiveReview}>
        <Formreview closeModalr={closeModalr} />
      </Modalla>
      <div
        className={
          infoSendedReview ? "sended_form_review active" : "sended_form_review"
        }
        onClick={() => setInfoSendedReview(!infoSendedReview)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedReview(infoSendedReview)}
          />
        </div>
      </div>
    </>
  );
}

export { Mainpage };
