import React, { useState } from "react";
import "../css/Screen1.css";
import { Modalla } from "../components/Modalla";
import { Ordercall } from "../components/Ordercall";
import { DateScript } from "../components/DateScript.js";
import rectangle9 from '../img/rectangle9.svg'
import radio from '../icons/radio.svg'
import arrow from "../icons/arrow.svg";
import parnik1 from "../img/parnik1.png";
import parnik2 from "../img/parnik2.png";
import { AiOutlineClose } from "react-icons/ai";


function Screen1() {
  const [modalActive, setModalActive] = useState(false);
  const [infoSendedForm, setInfoSendedForm] = useState("");
  const closeModal = () => {
    setModalActive(false);
    setInfoSendedForm(!infoSendedForm);
  };
  return (
    <>
      <div className="screen1">
        <div className="rectl">
          <img className="rectl_img" src={rectangle9} alt="rectl" />
        </div>
        <div className="screen1_block1">
          <h1 className="screen1_h1">Надёжный и удобный парник “Хлебница”</h1>
          <div className="screen1_span">от производителя</div>
          <div className="screen1_lis">
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Бесплатная доставка по всей России напрямую от производителя"
                />
              </div>
              <h3 className="screen1_list_item">
                Бесплатная доставка по всей России напрямую от производителя
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Полный комплект, включая поликарбонат"
                />
              </div>
              <h3 className="screen1_list_item">
                Полный комплект,
                <br />
                включая поликарбонат
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Оплата при получении"
                />
              </div>
              <h3 className="screen1_list_item">Оплата при получении</h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Грядка под парник - в ПОДАРОК!"
                />
              </div>
              <h3 className="screen1_list_item">
                Грядка под парник - в ПОДАРОК!
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Простая установка за 1 час"
                />
              </div>
              <h3 className="screen1_list_item">Простая установка за 1 час</h3>
            </div>
          </div>
          <div className="screen1_button">
            <button
              onClick={() => setModalActive(true)}
              className="main_button"
            >
              <span className="screen1_button_text">Заказать звонок</span>
              <img className="screen1_button_arrow" src={arrow} alt="arrow" />
            </button>
          </div>
        </div>
        <div className="screen1_block2">
          <h2 className="screen1_h2">
            Купите парник “Хлебницу”{<DateScript />}и получите скидку 41%
          </h2>
          <div className="screen1_block2_img">
            <img
              className="screen1_block2_img_img"
              src={parnik1}
              alt="Парник Хлебница и грядка в подарок"
            />
          </div>
        </div>
        <div className="screen1_block3">
          <img
            className="screen1_block3_img"
            width="397"
            src={parnik2}
            alt="Парник Хлебница и грядка в подарок"
          />
        </div>
      </div>
      <Modalla active={modalActive} setActive={setModalActive}>
        <Ordercall closeModal={closeModal} />
      </Modalla>
      <div
        className={infoSendedForm ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSendedForm(!infoSendedForm)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose onClick={() => setInfoSendedForm(infoSendedForm)} />
        </div>
      </div>
    </>
  );
}

export {Screen1}